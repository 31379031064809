<div>
	<div *ngIf="!comment.id; else regularComment">
		<div class="row d-flex justify-content-center">
			<div class="col-11 col-lg-9 col-xl-7 text-center dialogue-div status-message-bg">
				<p class="mb-0 semibold-text">{{ comment.body }}</p>
			</div>
		</div>
		<div class="row d-flex justify-content-center">
			<div class="col-11 col-lg-9 col-xl-7 text-center font-size-075 mb-3">
				<p>{{ getUserName(comment) }} &#8226; {{ comment.dateStr }}</p>
			</div>
		</div>
	</div>
	<ng-template #regularComment>
		<div *ngIf="!comment.isInternal || (comment.isInternal && loggedInUser.isAdmin)">
			<div class="row">
				<div class="col-12">
					<p class="mb-0 font-size-075">
						{{ getUserName(comment) }} &#8226; {{ comment.dateStr }}
						<a href="javascript:void(0)" (click)="toggleEditComment()" *ngIf="isCommentEditable(comment)">Edit</a>
					</p>
				</div>
			</div>
			<div class="row">
				<div
					class="col dialogue-div dialogue-message"
					[ngClass]="{
						'latest-post-bg': comment.isLatest && !comment.isInternal,
						'normal-message-bg': !comment.isLatest && !comment.isInternal,
						'private-message-bg': comment.isInternal,
					}">
					<div [innerHtml]="comment.body | newLinesToBreaks"></div>
					<div *ngIf="comment.attachments?.length">
						<tr class="text-nowrap" *ngFor="let attachment of comment.attachments">
							<td title="'Name'">
								<a
									[attr.href]="environment.baseUrl + '/attachments/' + attachment.attachmentId + '/view?access_token=' + environment.apiKey"
									target="_blank">
									<fa-icon [icon]="['fas', 'file']"></fa-icon> {{ attachment.originalName || attachment.name }}
								</a>
							</td>
						</tr>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col dialogue-div dialogue-message edit-message-bg" *ngIf="isEditingComment">
				<form [formGroup]="formGroup">
					<div class="row">
						<div class="col form-element-container" [class.invalid]="err.body">
							<label for="comment" class="required">Comment</label>
							<pk-broker-html-editor id="comment" formControlName="body" [invalid]="err.body"></pk-broker-html-editor>
							<p class="mb-0 invalid-feedback" *ngIf="err.body">
								<ng-container *ngIf="err.body.required">Comment is required</ng-container>
							</p>
						</div>
					</div>
					<div class="row my-2" *ngIf="comment.id && loggedInUser.isAdmin">
						<div class="col-auto form-element-container">
							<div class="custom-control custom-radio">
								<input type="radio" [value]="false" id="isInternal_0" formControlName="isInternal" class="custom-control-input" />
								<label class="custom-control-label" for="isInternal_0">Public</label>
							</div>
						</div>
						<div class="col-auto form-element-container">
							<div class="custom-control custom-radio">
								<input type="radio" [value]="true" id="isInternal_1" formControlName="isInternal" class="custom-control-input" />
								<label class="custom-control-label" for="isInternal_1">Private</label>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col pb-0 form-element-container">
							<pk-broker-file-selector
								#fileSelector
								label="Attachment(s)"
								formControlName="attachments"
								[multiple]="true"
								[softDelete]="false"
								accept=".doc,.docx,.pdf,.txt,.bmp,.gif,.png,.jpg,.jpeg,.tiff,.xlsx,.xls,.xlsm,.xlsb,.csv,.zip"
								title="Tickets"
								helperText="You may select multiple files from the dialog."
								[applyImmediately]="false">
							</pk-broker-file-selector>
						</div>
					</div>

					<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>

					<div class="row">
						<div class="col-12 text-sm-right">
							<button type="button" class="btn btn-link" (click)="toggleEditComment()">Cancel</button>
							<button type="button" class="btn btn-primary" (click)="submit()" [disabled]="submitDisabled">{{ submitText }}</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</ng-template>
</div>
