<div class="col home-page-container">
	<pk-broker-loader [show]="loading"></pk-broker-loader>
	<ng-container *ngIf="!loading">
		<div class="row mb-4" *ngIf="useSupportSystem">
			<div class="col-md-12">
				<section class="clearfix">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col mb-5">
									<h1 class="mb-0 add-underline">Request Support</h1>
								</div>
							</div>
							<div class="row d-flex justify-content-center mb-2">
								<div class="col col-lg-10 col-xl-8 col-xxl-6 form-element-container">
									<form [formGroup]="formGroup" (submit)="refreshDataGrid()">
										<div class="input-group main-search-faq">
											<input
												type="text"
												class="form-control"
												placeholder="Search - (Minimum of 4 characters required)"
												formControlName="search"
												aria-label="Issue Search"
												aria-describedby="issueSearch" />
											<div class="input-group-append">
												<button class="btn btn-info" type="button" id="issueSearch" (click)="refreshDataGrid()">
													<fa-icon [icon]="['fas', 'search']"></fa-icon>
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div class="row">
								<div class="col">
									<h2 class="mb-1 no-underline gray-text">{{ statusText }}</h2>
								</div>
								<div class="col-auto">
									<p>
										<a href="javascript:void(0)" class="semibold-text" (click)="openTicketAddModal()">
											<fa-icon [icon]="['fas', 'plus']"></fa-icon>New Ticket
										</a>
									</p>
								</div>
							</div>
							<div id="ticketGridTopScroll" class="gridTopScroll">
								<div id="ticketGridTopScrollInner" class="gridTopScrollInner"></div>
							</div>
							<dx-data-grid
								id="gridContainer"
								class="table-sm"
								[dataSource]="ticketsDataSource"
								[allowColumnResizing]="true"
								[columnAutoWidth]="true"
								[showBorders]="true"
								[remoteOperations]="true"
								[rowAlternationEnabled]="true"
								[hoverStateEnabled]="true"
								(onInitialized)="ticketGrid = $event.component"
								(onContentReady)="onTicketDataGridReady($event)"
								(onRowDblClick)="onTicketDblClick($event)">
								<dxo-scrolling [useNative]="true"></dxo-scrolling>
								<dxo-header-filter [visible]="true"></dxo-header-filter>
								<dxi-column
									caption="Ticket ID"
									[fixed]="!isMobile"
									[width]="105"
									dataField="id"
									dataType="number"
									[allowHeaderFiltering]="false"
									cellTemplate="ticketNumCellTemplate">
								</dxi-column>
								<dxi-column caption="Subject" dataField="subject" dataType="string" [allowHeaderFiltering]="false"></dxi-column>
								<dxi-column
									caption="Category"
									dataField="ticketCategoryName"
									dataType="string"
									[filterValues]="defaultFilters.defaultTicketCategories">
									<dxo-header-filter [dataSource]="filters.ticketCategorySelections"></dxo-header-filter>
								</dxi-column>
								<dxi-column
									caption="Priority"
									dataField="priority"
									[visible]="loggedInUser.isAdmin"
									dataType="string"
									cellTemplate="priorityCellTemplate"
									[filterValues]="defaultFilters.defaultPriorities">
									<dxo-header-filter [dataSource]="filters.prioritySelections"></dxo-header-filter>
								</dxi-column>
								<dxi-column
									caption="Last Comment"
									dataField="lastCommentDate"
									[visible]="loggedInUser.isAdmin"
									dataType="string"
									cellTemplate="lastCommentCellTemplate"
									[allowHeaderFiltering]="false">
								</dxi-column>
								<dxi-column
									caption="Last Comment By"
									dataField="lastCommentByBroker"
									[visible]="loggedInUser.isAdmin"
									dataType="string"
									cellTemplate="lastCommentByCellTemplate"
									[allowHeaderFiltering]="false">
								</dxi-column>
								<dxi-column
									caption="Status"
									dataField="status"
									dataType="string"
									cellTemplate="statusTemplate"
									[filterValues]="defaultFilters.defaultStatusCategories">
									<dxo-header-filter [dataSource]="filters.statusSelections"></dxo-header-filter>
								</dxi-column>
								<dxi-column
									[visible]="loggedInUser.isAdmin"
									caption="Reporter"
									dataField="reporterFullName"
									dataType="string"
									cellTemplate="reporterCellTemplate"
									[allowHeaderFiltering]="false">
								</dxi-column>
								<dxi-column
									[visible]="loggedInUser.isAdmin"
									caption="Reporter Role"
									dataField="reporterRole"
									dataType="string"
									[filterValues]="defaultFilters.defaultReporterRole">
									<dxo-header-filter [dataSource]="filters.reporterRoleSelections"></dxo-header-filter>
								</dxi-column>
								<dxi-column [visible]="loggedInUser.isAdmin" caption="Assignee" dataField="assigneeFullName" dataType="string">
									<dxo-header-filter [dataSource]="filters.assignedToUserSelections" [width]="450"></dxo-header-filter>
								</dxi-column>
								<dxi-column caption="Add Date" dataField="addDate" dataType="date">
									<dxo-header-filter [dataSource]="filters.addDate"></dxo-header-filter>
								</dxi-column>
								<dxi-column caption="Completed By" dataField="completedFullName" dataType="string" cellTemplate="completedFullNameCellTemplate">
									<dxo-header-filter [dataSource]="filters.completeUserSelections" [width]="450"></dxo-header-filter>
								</dxi-column>
								<dxi-column caption="Complete Date" dataField="completeDate" dataType="date" [calculateCellValue]="calculateCompleteDateDisplay">
									<dxo-header-filter [dataSource]="filters.completeDate"></dxo-header-filter>
								</dxi-column>
								<dxo-paging [pageSize]="25"></dxo-paging>
								<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
								<div *dxTemplate="let d of 'ticketNumCellTemplate'">
									<div class="chart-cell">
										<a [routerLink]="[d.data.id, 'info']" target="_blank">{{ d.data.id }}</a>
									</div>
								</div>
								<div *dxTemplate="let d of 'priorityCellTemplate'">
									<div class="chart-cell">
										{{ d.data.priorityDisplay }}
									</div>
								</div>
								<div *dxTemplate="let d of 'lastCommentCellTemplate'">
									<div class="chart-cell">
										{{ d.data.lastCommentDate | date: 'MM/dd/yyyy hh:mm:ss a' }}
									</div>
								</div>
								<div *dxTemplate="let d of 'lastCommentByCellTemplate'">
									<div class="chart-cell">
										{{ d.data.lastCommentByBroker ? 'Broker' : 'Ops' }}
									</div>
								</div>
								<div *dxTemplate="let d of 'reporterCellTemplate'">
									<div class="chart-cell">
										{{ d.data.reporterAgentName ?? d.data.reporterFullName }}
									</div>
								</div>
								<div *dxTemplate="let d of 'statusTemplate'">
									<div
										[ngClass]="calculateStatusColorDisplay(calculateStatusDisplay(d.data))"
										*ngIf="calculateStatusDisplay(d.data) === 'New Response'">
										<fa-icon [icon]="['fas', 'circle']"></fa-icon>
										<span class="ml-1">
											<a [routerLink]="['/ticket', d.data.id, 'info']" target="_blank" class="semibold-text">{{ calculateStatusDisplay(d.data) }}</a>
										</span>
									</div>
									<div *ngIf="calculateStatusDisplay(d.data) !== 'New Response'">
										<fa-icon [ngClass]="calculateStatusColorDisplay(calculateStatusDisplay(d.data))" [icon]="['fas', 'circle']"></fa-icon>
										<span class="ml-1">{{ calculateStatusDisplay(d.data) }}</span>
									</div>
								</div>
								<div *dxTemplate="let d of 'completedFullNameCellTemplate'">
									<div class="chart-cell">
										{{ d.data.getCompletedFullName(loggedInUser) }}
									</div>
								</div>
							</dx-data-grid>
						</div>
					</div>
				</section>
			</div>
		</div>
		<div class="row mb-4" *ngIf="!useSupportSystem">
			<div class="col-md-12">
				<section class="clearfix">
					<p>You do not have permission to view the built-in support dashboard. Please ask your manager to allow this permission on your account.</p>
				</section>
			</div>
		</div>
	</ng-container>
</div>
