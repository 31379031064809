import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthResolver } from '../shared/resolvers/auth.resolver';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { TicketComponent } from './ticket/ticket.component';

const routes: Routes = [
	{
		path: '',
		component: TicketComponent,
		resolve: { auth: AuthResolver },
		data: { showFeedback: 'Support Ticket System', allowInactive: true },
	},
	{
		path: ':id/info',
		component: TicketDetailComponent,
		resolve: { auth: AuthResolver },
		data: { showFeedback: 'Ticket Detail', allowInactive: true },
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class TicketRoutingModule {}
