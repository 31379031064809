import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CommentBlockComponent } from './components/comment-block/comment-block.component';
import { TicketAddEditModalComponent } from './modals/ticket-add-edit/ticket-add-edit.component';
import { TicketDetailComponent } from './ticket-detail/ticket-detail.component';
import { TicketRoutingModule } from './ticket-routing.module';
import { TicketComponent } from './ticket/ticket.component';

@NgModule({
	imports: [CommonModule, SharedModule, TicketRoutingModule],
	declarations: [TicketComponent, TicketAddEditModalComponent, TicketDetailComponent, CommentBlockComponent],
})
export class TicketModule {}
