import { Component, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';

import _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { NotificationService } from 'src/app/notification/services/notification.service';
import { FileSelectorComponent } from 'src/app/shared/components/form/file-selector/file-selector.component';
import { AbstractPage } from 'src/app/shared/pages/page.abstract';
import {
	Attachment,
	Breadcrumb,
	CommentView,
	ContactMethod,
	Ticket,
	TicketCategory,
	TicketComment,
	TicketStatus,
	User,
	ViewTicketDashboard,
} from '../../shared/models';
import { TicketAddEditModalComponent } from '../modals/ticket-add-edit/ticket-add-edit.component';

@Component({
	selector: 'pk-broker-ticket-detail',
	templateUrl: './ticket-detail.component.html',
	styleUrls: ['./ticket-detail.component.scss'],
})
export class TicketDetailComponent extends AbstractPage {
	@ViewChild(FileSelectorComponent) fileSelector: FileSelectorComponent;

	public static breadcrumbs = [
		{ text: 'Dashboard', route: '/' },
		{ text: 'Tickets', route: '/ticket' },
		{ text: 'Ticket Info', isLast: true },
	] as Breadcrumb[];

	public id: number;
	public ticket: ViewTicketDashboard;

	public comments: CommentView[];
	public priorityList: { text: string; value: number }[] = [
		{ text: 'Blocker', value: 5 },
		{ text: 'Highest', value: 4 },
		{ text: 'High', value: 3 },
		{ text: 'Medium', value: 2 },
		{ text: 'Low', value: 1 },
	];
	public assignedToUserList: { text: string; value: User }[];
	public statusFilterDropdownList: { text: string; value: TicketStatus }[];
	public ticketCategories: TicketCategory[];
	public ticketStatuses: TicketStatus[];
	public users: User[];
	public contactMethods: ContactMethod[];

	public commentOrder: 'newest' | 'oldest' = 'newest';
	public isAddingComment = false;
	public canAddComment = true;
	public loadingCommentEditor = true;

	public isCompleteButtonText = 'Resolve Ticket';
	public isCompleteButtonDisabled = false;

	public deleteButtonText = 'Remove Ticket';
	public isDeleteButtonDisabled = false;

	constructor(
		private graphqlService: GraphqlService,
		private modalService: BsModalService,
		private route: ActivatedRoute,
		private toastrService: ToastrService,
		private fb: FormBuilder,
		private notificationService: NotificationService,
	) {
		super(TicketDetailComponent.breadcrumbs, 'Ticket Info');
		this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
			this.id = Number(paramMap.get('id'));
		});
		this.submitAudit = 'ticket-details:createComment';
	}

	public async loadPageData(): Promise<void> {
		const result = await Promise.all([this.graphqlService.getTicketDetailPageData(), this.refreshTicket()]);

		this.ticketCategories = result[0].data?.ticketCategories?.message
			.map(t => new TicketCategory(t))
			.filter(t => this.loggedInUser.isAdmin || t.roles.map(r => r.roleId).includes(this.loggedInUser.roleId));
		this.ticketStatuses = result[0].data.ticketStatuses.message.map(t => new TicketStatus(t));
		this.statusFilterDropdownList = this.ticketStatuses.map(ts => ({ text: ts.name, value: ts }));
		this.users = result[0].data.users.message.map(u => new User(u));

		// by doing this we can still show the assigned user even if they're inactive.
		this.assignedToUserList = _.uniqBy(
			[
				{
					text: this.ticket.assigneeFullName,
					value: new User({
						userId: this.ticket.assignedToUserId,
						fname: this.ticket.assigneeFname,
						lname: this.ticket.assigneeLname,
						fullName: this.ticket.assigneeFullName,
					}),
				},
				...this.users.map(u => ({ text: u.fname + ' ' + u.lname, value: new User(u) })),
			],
			u => u.text,
		);
		this.contactMethods = result[0].data.contactMethods?.message;

		if (!this.loggedInUser.isAdmin && this.ticket.isCompleted) {
			this.canAddComment = false;
		}
	}

	private async refreshTicket(): Promise<void> {
		const result = await this.graphqlService.getViewTicketDashboardDetailData(this.id);
		this.ticket = new ViewTicketDashboard(result.data.viewTicketDashboard.message[0]);
		this.comments = this.reformatComments(this.ticket, _.cloneDeep(this.ticket.comments));
	}

	public getForm() {
		return this.fb.group({
			body: ['', [Validators.required]],
			ticketId: this.id,
			attachments: this.fb.control([] as Attachment[]),
			bcc: this.fb.control(null as string[]),
			isInternal: this.fb.control(this.ticket.isCompleted),
			includeOCAttachment: false,
			ocAttachmentId: '',
		});
	}

	get isImpersonating() {
		return this.securityService.isImpersonating;
	}
	get isResidential(): string {
		return (this.ticket?.agent?.residentialContractRatio || 0) > 90 ? 'Yes' : 'No';
	}
	get ticketContactMethods(): string {
		return this.ticket?.contactMethods?.map(c => c.name).join(', ') || '';
	}
	get ticketStatus(): string {
		return this.loggedInUser.isAdmin ? this.ticket.status : this.ticket.brokerStatus;
	}

	protected async onFormSubmitted() {
		if (this.isImpersonating) {
			this.toastrService.warning('You are still impersonating, please logout and log back in so you can edit this ticket.', 'Tickets');
			return;
		}

		await this.graphqlService.createTicketComment({
			body: this.form.body.value,
			ticketId: this.form.ticketId.value,
			bcc: this.form.bcc.value,
			isInternal: this.form.isInternal.value,
			attachments: this.form.attachments.value.map(a => ({ attachmentId: a.attachmentId })),
			ocAttachmentId: this.form.ocAttachmentId.value,
		});

		await this.refreshTicket();
		this.toggleAddComment();

		this.toastrService.success('Successfully Saved Comment', 'Tickets');
	}

	protected async onFormLoaded() {
		if (this.ticket?.latestAiGeneratedContent?.attachmentId) {
			this.form.includeOCAttachment.valueChanges.subscribe(includeOCAttachment => {
				this.form.ocAttachmentId.setValue(null);

				if (includeOCAttachment) {
					this.form.ocAttachmentId.setValue(this.ticket?.latestAiGeneratedContent?.attachmentId);
				}
			});
		}
	}

	public async changePriority(priority: { text: string; value: number }): Promise<void> {
		await this.updateTicket({ priority: priority.value });
	}

	public async changeStatus(status: { text: string; value: TicketStatus }): Promise<void> {
		await this.updateTicket({ ticketStatusId: status.value.id });
	}

	public async changeAssignedUser(user: { text: string; value: User }): Promise<void> {
		await this.updateTicket({ assignedToUserId: user.value.userId });
	}

	public async onCommentSubmitted(): Promise<void> {
		await this.refreshTicket();
	}

	public toggleAddComment(): void {
		this.isAddingComment = !this.isAddingComment;
		if (!this.isAddingComment) {
			this.loadingCommentEditor = true;
		}
		this.reloadForm();
	}

	public reorderComments(): void {
		if (this.commentOrder === 'newest') {
			this.commentOrder = 'oldest';
			this.comments = _.orderBy(this.comments, c => c.date);
		} else {
			this.commentOrder = 'newest';
			this.comments = _.orderBy(this.comments, c => c.date, 'desc');
		}
	}

	public async setToComplete(): Promise<void> {
		if (this.isCompleteButtonDisabled || this.isDeleteButtonDisabled) {
			return;
		}
		if (this.isImpersonating) {
			this.toastrService.warning('You are still impersonating, please logout and log back in so you can change the status of the ticket.', 'Tickets');
			return;
		}
		this.isCompleteButtonText = 'Processing...';
		this.isCompleteButtonDisabled = true;
		if (await this.updateTicket({ ticketStatusId: this.CONSTANTS.ticketStatuses.complete })) {
			await this.refreshTicket();
		}

		this.isCompleteButtonText = 'Resolve Ticket';
		this.isCompleteButtonDisabled = false;
	}

	public async openDeleteTicketModal(): Promise<void> {
		const result = await this.notificationService.confirm(
			{
				title: 'Delete ticket?',
				body: `Do you wish to delete this ticket?`,
			},
			'confirm',
			'pk-modal modal-dialog-centered',
		);

		if (result) {
			this.deleteTicket();
		}
	}

	public async deleteTicket(): Promise<void> {
		if (this.isCompleteButtonDisabled || this.isDeleteButtonDisabled) {
			return;
		}
		if (this.isImpersonating) {
			this.toastrService.warning('You are still impersonating, please logout and log back in so you can change the status of the ticket.', 'Tickets');
			return;
		}
		this.deleteButtonText = 'Processing...';
		this.isDeleteButtonDisabled = true;
		if (await this.updateTicket({ ticketStatusId: this.CONSTANTS.ticketStatuses.complete, suppressNotifications: true })) {
			await this.refreshTicket();
		}

		this.deleteButtonText = 'Remove Ticket';
		this.isDeleteButtonDisabled = false;
	}

	private async updateTicket(ticket: Partial<Ticket>): Promise<boolean> {
		try {
			if (this.isImpersonating) {
				this.toastrService.warning('You are still impersonating, please logout and log back in so you can edit this ticket.', 'Tickets');
				return null;
			} else {
				const result = await this.graphqlService.updateTicket(this.ticket.id, ticket);
				const updatedTicket = result.data.updateTicket;

				Object.assign(this.ticket, updatedTicket);

				this.toastrService.success('Successfully Updated Ticket', this.pageTitle);

				return true;
			}
		} catch (e) {
			this.toastrService.warning('There was a problem updating the ticket', this.pageTitle);
		}

		return false;
	}

	public reformatComments(ticket: ViewTicketDashboard, comments: TicketComment[]): CommentView[] {
		// To restrict comment from being editable if admin comments after they've made the comment
		// first find the latest non-internal comment, then use that as an anchor to see what is editable
		const latestIndex = comments.findIndex(r => !r.isInternal);
		const reformattedComments = comments.map(
			(c, index) =>
				new CommentView({
					id: c.id,
					body: c.body,
					user: c.user.fname + ' ' + c.user.lname,
					userId: c.user.userId,
					userIsAdmin: c.user.isAdmin,
					currUserId: this.loggedInUser.userId,
					isInternal: c.isInternal,
					isLatest: latestIndex === index,
					attachments: this.getLinkedAttachments(c.id, false, this.ticket.attachments),
					isEditable: (c.isInternal && index < latestIndex) || latestIndex === index,
					date: c.addDate,
					dateStr: '',
				}),
		);

		// add the ticket body to the comment list
		reformattedComments.push(
			new CommentView({
				...ticket,
				id: -1,
				user: ticket.reporterFullName,
				userId: ticket.reporterId,
				currUserId: this.loggedInUser.userId,
				isInternal: false,
				isLatest: false,
				attachments: this.getLinkedAttachments(null, true, this.ticket.attachments),
				isEditable: false,
				date: new Date(ticket.addDate),
				dateStr: '',
			}),
		);

		// Because in inProgressDate in some scenarios is matched equal to the time of
		// admin making comment so their times are the same. This ensures that the
		// status progress entity shows up before first admin comment
		if (this.ticket.inProgressDate) {
			this.ticket.inProgressDate.setSeconds(this.ticket.inProgressDate.getSeconds() - 1);
			reformattedComments.push(
				new CommentView({
					id: null,
					body: 'Status changed to In-Progress',
					user: ticket.assigneeFullName,
					userId: ticket.assignedToUserId,
					currUserId: this.loggedInUser.userId,
					isInternal: false,
					isLatest: false,
					attachments: [],
					isEditable: false,
					date: this.ticket.inProgressDate,
					dateStr: '',
				}),
			);
		}

		return this.commentOrder === 'newest' ? _.orderBy(reformattedComments, c => c.date, 'desc') : _.orderBy(reformattedComments, c => c.date);
	}

	public getLinkedAttachments(id: number, isBody: boolean, attachments: Attachment[]): Attachment[] {
		const commentAttachments: Attachment[] = [];
		for (const a of attachments) {
			if (isBody && !a.ticketCommentId) {
				commentAttachments.push(a);
			} else if (a.ticketCommentId === id) {
				commentAttachments.push(a);
			}
		}
		return commentAttachments;
	}

	public displayCommentAttachment(form: FormData): Attachment {
		const a = { originalName: form.get('description') } as Attachment;
		return a;
	}

	private getTicketDto(viewTicket: ViewTicketDashboard): Partial<Ticket> {
		return {
			...viewTicket,
			reportedUserId: viewTicket.reporterId,
			contractId: null,
			emailId: null,
			category: { name: viewTicket.ticketCategoryName } as TicketCategory,
			assignedToUser: null,
			completeUser: null,
			ticketStatusId: viewTicket.statusId,
		};
	}

	public openTicketEditModal(viewTicket: ViewTicketDashboard): void {
		const ticket = this.getTicketDto(viewTicket);
		const modalRef = this.modalService.show(TicketAddEditModalComponent, {
			class: 'pk-modal modal-xl',
			backdrop: 'static',
			initialState: {
				ticket,
				ticketCategories: this.ticketCategories.filter(
					c =>
						ticket.ticketCategoryId === this.CONSTANTS.ticketCategories.reactivationRequest ||
						c.id !== this.CONSTANTS.ticketCategories.reactivationRequest,
				),
				users: this.users,
				contactMethods: this.contactMethods,
				isAddingNewTicket: false,
			},
		});
		modalRef.content.onSubmit.subscribe(async () => {
			this.loading = true;
			await this.refreshTicket();
			this.loading = false;
		});
	}

	public onLoadedCommentEditor(): void {
		this.loadingCommentEditor = false;
	}
}
