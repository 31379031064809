import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CONSTANTS } from '@pk/powerkioskutils';

export const ticketContractsValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
	const ticketCategoryId = control.get('ticketCategoryId').value;
	const isResidential = control.get('isResidential').value;
	const contracts = control.get('contracts').value;
	const ticketCategoryRequiresContract = [CONSTANTS.ticketCategories.commission].includes(Number(ticketCategoryId));
	const valid: boolean = contracts.length > 0 || !ticketCategoryRequiresContract;

	return valid ? null : { noSelectedContract: true };
};
