import { Component, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { ContractSelectorModalComponent } from 'src/app/shared/components/form/contract-selector/contract-selector-modal/contract-selector-modal.component';
import { FileSelectorComponent } from 'src/app/shared/components/form/file-selector/file-selector.component';
import { ticketContractsValidator } from 'src/app/shared/validators/ticket.contracts.validator';
import { GraphqlService } from '../../../graphql/graphql.service';
import { Attachment, ContactMethod, Contract, Ticket, TicketCategory, User } from '../../../shared/models';
import { AbstractPageForm } from '../../../shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-ticket-add-edit',
	templateUrl: './ticket-add-edit.component.html',
	styleUrls: ['./ticket-add-edit.component.scss'],
})
export class TicketAddEditModalComponent extends AbstractPageForm {
	@ViewChild(FileSelectorComponent) fileSelector: FileSelectorComponent;

	// outputs
	public onSubmit: EventEmitter<boolean> = new EventEmitter();

	// inputs
	public ticket: Partial<Ticket>;
	public ticketCategories: TicketCategory[];
	public users: User[];
	public contactMethods: ContactMethod[];
	public isAddingNewTicket: boolean;

	constructor(
		private graphqlService: GraphqlService,
		private toastrService: ToastrService,
		public activeModal: BsModalRef,
		private fb: FormBuilder,
		private modalService: BsModalService,
	) {
		super();
		this.submitText = 'Save';
		this.submittingText = 'Saving...';
		this.submitAudit = 'ticket-add-edit:submit';
	}

	public userTextMap = (option: User) => option.getLabel();

	public async loadPageData(): Promise<void> {}

	public getForm() {
		return this.fb.group(
			{
				isResidential: this.fb.control({ value: (this.ticket.agent?.residentialContractRatio || 0) >= 90, disabled: true }),
				subject: [this.ticket.subject, [Validators.required, Validators.maxLength(100)]],
				ticketCategoryId: [this.ticket.ticketCategoryId, [Validators.required]],
				reporter: [this.ticket.reportedUser, [Validators.required]],
				assignedToUserId: [this.ticket.assignedToUserId],
				contracts: this.fb.array(this.ticket.contracts ?? []),
				email: [this.ticket.email, [Validators.required, Validators.maxLength(200)]],
				//Keep to default the contact method to email (or prexisting contact method) but not required as not a field anymore
				contactMethods: this.fb.array(
					this.contactMethods?.map(cm =>
						this.fb.group({
							id: cm.id,
							name: cm.name,
							checked: (this.ticket.contactMethods?.some(ucm => ucm.id === cm.id) || cm.name === 'Email') ?? false,
						}),
					),
				),
				body: [this.ticket.body, [Validators.required]],
				attachments: this.fb.control((this.ticket.attachments ?? []) as Attachment[]),
			},
			{ validators: [ticketContractsValidator] },
		);
	}

	public async onFormLoaded(): Promise<void> {
		if (this.ticket.id && this.loggedInUser.agent) {
			this.form.subject.disable();
			this.form.ticketCategoryId.disable();
			this.form.body.disable();
		}

		this.form.reporter.valueChanges.subscribe(reporter => {
			this.form.email.setValue(reporter.email);
		});
	}

	protected async onFormSubmitted() {
		if (this.securityService.isImpersonating) {
			this.toastrService.warning('You are still impersonating, please logout and log back in so you can add/edit this ticket.', 'Ticket');
			return;
		}

		if (this.ticket.id) {
			await this.graphqlService.updateTicket(this.ticket.id, this.buildTicketDTO());
		} else {
			const result = await this.graphqlService.createTicket(this.buildTicketDTO());
			this.ticket.id = result.data.createTicket.id;
		}

		await this.fileSelector.applyChanges({
			ticketId: String(this.ticket.id),
			attachmentTypeId: this.CONSTANTS.attachmentTypes.document,
		});

		this.toastrService.success('Successfully Saved Ticket', 'Ticket');
		this.onSubmit.emit(true);
		this.activeModal.hide();
	}

	private buildTicketDTO(): Subset<Ticket> {
		const dto = {
			...this.formGroup.value,
			ticketCategoryId: Number(this.form.ticketCategoryId.value),
			attachments: this.ticket.attachments?.map(a => ({
				attachmentId: a.attachmentId,
				originalName: a.originalName,
				size: a.size,
			})),
			contactMethods: this.form.contactMethods.filter(cm => cm.checked.value).map(cm => ({ id: cm.id.value })),
			contracts: this.form.contracts?.map(c => ({ contractId: c.value.contractId })),
			reportedUserId: this.form.reporter.value.userId,
		};

		delete dto.reporter;

		return dto;
	}

	public lookupContract(): void {
		const modalRef = this.modalService.show(ContractSelectorModalComponent, { class: 'pk-modal modal-xl' });
		modalRef.content.onSubmit.subscribe((contract: Contract) => {
			this.form.contracts.control.push(this.fb.control(contract));
		});
	}
}
